import { createElement as _C, useState, useEffect } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import '../resources/mooncake.less';
import { Row, Col, doAuth, parseQueryString, defineRequest, alert, post, IRequestDataState, wechatPaySubmit, useLoading, wrapUseState, APP_ID } from './common';
import { PhoneField, checkPhoneFormat } from './form';
import SwipeableViews from 'react-swipeable-views';
import { NavLink, Link } from 'react-router-dom';
import { now } from './datetime';
import { Select, ISelectProps } from './select';
import { createPortal } from 'react-dom';

const assert = require('assert');

const THIRD_PARTY_NAME = 'third';

interface IGoodsType {
  isShow: boolean;
  name: string;
  description: string;
  price: number;
  imgs: string[];
}


interface MoonCakeDetail {
  unitPrice: number;
  quantity: number;
  shopName: string;
}

interface MoonCakeAddress {
  address: string;
  consignee: string;
  tel: string;
}

interface FormData {
  deliveryTime: string;
  referencePhone: string;
  moonCakeDetails: MoonCakeDetail[];
  totalMoney: number;
  remark: string;
  userName: string;
  moonCakeAddress: MoonCakeAddress | null;
  phone: string;
  isSelf: number;
}

interface OrderData extends FormData {
  orderId: number;
  orderNo: string;
  createdTime: string;
  orderStatus: number;
  expressNo: string | null;
  expressName: string | null;
}

interface PayRequestData {
  orderId: number;
  openid: string;
}

interface PayResponseData {
  data: {
    timeStamp: string;
    package: string;
    paySign: string;
    appId: string;
    signType: string;
    nonceStr: string;
  }
}

/**
 * 需要显示的商品信息定义
 */
let goodsType: IGoodsType[] = [];

/**
 * 全部商品定义
 */
let allGoodsType: IGoodsType[] = [];

/**
 * 商品选择状态控制
 */
const useGoodsStatus = (): [number[], (c: number, i: number) => void] => {
  const [state, setState] = useState(goodsType.map(() => 0));
  const _setState = (count: number, i: number) => {
    setState(state.map((v, j) => i == j ? Math.max(0, count) : v));
  }
  return [state, _setState];
}

const makeFullUrl = (uri: string) => {
  const { protocol, host, pathname } = location;
  return `${protocol}//${host}${pathname}#${uri}`;
}

/**
 * 生成微信授权的回调地址
 * @param param0
 */
const getResultUrl = ({
  third = 'false',
  employee = '',
  counts = new Array<number>()
}) => makeFullUrl(`/mooncake/form?employee=${employee}&counts=${encodeURIComponent(JSON.stringify(counts))}&third=${third}`);

/**
 * 根据商品数量计算运费, 以及总额
 * @param counts
 */
const getAmount = (counts: number[], useFee = true) => {
  const allCount = counts.reduce((sum, n) => sum + n, 0),  // 总数
    fee = allCount > 0 ? allCount * 8 + 5 : 0,  // 运费
    total = goodsType.reduce((sum, { price }, i) => sum + price * counts[i], 0) + (useFee ? fee : 0);  // 总额
  return { allCount, fee, total };
};

/**
 * 数量选择组件
 * @param param0
 */
const NumBtn = ({ value = 0, onChange = (v: number) => { } }) => {
  const [val, setVal] = useState({ val: '', edit: false });
  return <Row className="num-btn">
    {value > 0 && <a href="javascript:;" className="subtract" onClick={() => onChange(value - 1)}>
      <img src={require('../resources/img/subtract.svg')} />
    </a>}
    {value > 0 && <input className="amount" value={val.edit ? val.val : value}
      type="number"
      onFocus={() => setVal({ val: value.toString(), edit: true })}
      onChange={(evt) => setVal({ val: evt.target.value, edit: true })}
      onBlur={() => {
        setVal({ val: '', edit: false });
        onChange(Math.max(1, parseInt(val.val, 10) || 0));
      }} />}
    <a href="javascript:;" className="add" onClick={() => onChange(value + 1)}>
      <img src={require('../resources/img/add.svg')} />
    </a>
  </Row>
};

/**
 * radio 单选组件
 * @param param0
 */
const RadioGroup = ({ value = '', onChange = (v: string) => { }, options = {}} ) => <div className="radio-group">
  {Object.entries(options).map(([key, val]) =>
    <a href="javascript:;" key={key} className={key == value ? 'active' : ''} onClick={() => onChange(key)}>{val as string}</a>)}
</div>;

/**
 * 商品详情弹窗
 * @param param0
 */
const GoodsDetail = ({ src = '', onCancel = () => {} }) => createPortal(<div className="goods-mask" onClick={() => onCancel()}>
  <img src={src} onClick={(evt) => evt.stopPropagation()} />
  <a href="javascript:;" className="close" onClick={() => onCancel()}>X</a>
</div>, document.body);

/**
 * 可点击弹出详情的商品图片
 * @param param0
 */
const GoodsImg = ({ imgs, over = false, hurry = false  }: {imgs: string[], over: boolean, hurry : boolean }) => {
  const [isShow, setShow] = useState(false);
  return <div className="goods">
    {over && <div className="mask">已售罄</div>}
    {hurry && <div className="tag">LAST SALE</div>}
    <img src={imgs[0]} onClick={() => setShow(true)} />
    {isShow && <GoodsDetail src={imgs[1]} onCancel={() => setShow(false)} />}
  </div>;
}

/**
 * 选择商品页面
 * @param param0
 */
const BuyPage = (props: RouteComponentProps) => {
  const { location } = props;
  const [counts, setCounts] = useGoodsStatus();
  const { employee = '', employeeName = '', third = 'false' } = parseQueryString(location.search);
  const { total, allCount } = getAmount(counts, false);


  return <div className="buy-page">
    <img className="bg" src={require('../resources/img/mooncake-bg2.jpg')} />
    {employeeName === THIRD_PARTY_NAME && <h1 className="bg-info">BEEPLUS中秋尊享月饼</h1>}
    {employeeName !== THIRD_PARTY_NAME && <h1 className="bg-info">您的好友<span className="name">{decodeURIComponent(employeeName)}</span>向您推荐了 <br /> BEEPLUS中秋尊享月饼</h1>}
    <div className="item-list">
      {goodsType.map((item, i) => {
        const isOver = item.name.indexOf('六') > -1;
        const isHurry = item.name.indexOf('魔方') > -1;
        return <Row className={"item" + (isOver ? ' over' : '')} key={i}>
          <GoodsImg imgs={item.imgs} over={isOver} hurry={isHurry} />
          <Col className="info">
            <h1>{item.name}</h1>
            <div className="desc">{item.description}</div>
            <Row className="bot">
              {third != 'false' && <span className="price">￥{item.price}</span>}
              {third == 'false' && <span className="price">￥{item.price}/<span className="en">内部优惠价</span></span>}
              {!isOver && <NumBtn value={counts[i]} onChange={(v) => setCounts(v, i)} />}
            </Row>
          </Col>
        </Row>
      })}
    </div>
    <div className="footer">
      <Row className="first">
        <div className="package">
          <img src={require('../resources/img/mooncake-package.png')} />
          <span className="count">{allCount}</span>
        </div>
        <div className="amount-info">
          {/* <span className="fee">运费: ￥{fee}</span> */}
          <span className="total-label">合计:</span>
          <span className="total-price">￥{total.toFixed(2)}</span>
          <button className="submit" onClick={() => {
            if (allCount > 0) {
              doAuth(getResultUrl({ employee, counts, third }))
            } else {
              alert('您还没有选择任何商品');
            }
          }}>结算</button>
        </div>
      </Row>
      <FooterNav {... props} />
    </div>
  </div>;
}

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  model: [string, (v: string) => void];
}

interface FormSelectProps extends ISelectProps {
  model: [string, (v: string) => void];
}

interface SwitchBtnProps {
  model: [boolean, (v: boolean) => void];
}

const FormInput = ({ model: [value, doInput], ...others }: FormInputProps) =>
  <input {...others} value={value} onChange={(evt) => doInput(evt.target.value)} />;

const FormSelect = ({ model: [value, doInput], ...others }: FormSelectProps) =>
  <Select {...others} value={value} onChange={(evt) => doInput(evt.target.value)} />;

/**
 * 切换按钮
 */
const SwitchBtn = ({ model: [value, doInput] }: SwitchBtnProps) => {
  return <div className={"switch-btn " + (value ? 'active' : '')} onClick={() => doInput(!value)}>
    <div className="bar" />
    <div className="btn" />
  </div>;
}

/**
 * 发送
 */
const submitFormData = async (formData: FormData & { authCode: string }) => {
  const { authCode, ... data } = formData;
  checkPhoneFormat(data.phone, '请输入您的正确手机号码');
  assert(authCode != null, '验证码不可为空');
  if (data.isSelf == 0) {
    if (data.moonCakeAddress == null) {
      throw new Error('必须要有moonCakeAddress');
    }
    assert(data.moonCakeAddress.address.length > 0, '收货地址不可为空');
    assert(data.moonCakeAddress.consignee.length > 0, '收货人不可为空');
    checkPhoneFormat(data.moonCakeAddress.tel, '请输入正确的收货人手机号码');
  }

  await post('/member/checkCode', { phone: data.phone, code: authCode });
  const { result } = await post('/mooncake/addOrder', data);
  return result;
};


const df = 'YYYY-MM-DD';
const dates = ['2019-08-27', '2019-09-03', '2019-09-10'];

/**
 * 月饼订单表单
 */
const FormPage = ({ location, history }: RouteComponentProps) => {
  const { employee = '', counts = encodeURIComponent('[]'), openid = '', third = 'false' } = parseQueryString(location.search);
  // const today = now();
  const address = useState(''),
    consignee = useState(''),
    userName = useState(''),
    authCode = useState(''),
    phone = useState(''),
    tel = useState(''),
    remark = useState(''),
    sendTime = useState(dates[0]),
    isSelf = useState(0),
    buyForOther = useState(false);
  const countsArr = JSON.parse(decodeURIComponent(counts)) as number[];
  // const [{ data, isLoading, error }, request] = useOrderRequest();
  const { total, fee } = getAmount(countsArr, isSelf[0] == 0);

  const submit = useLoading(() => submitFormData({
    isSelf: isSelf[0],
    authCode: authCode[0],
    deliveryTime: sendTime[0],
    referencePhone: employee,
    phone: phone[0],
    remark: remark[0],
    userName: userName[0],
    totalMoney: Math.round(total * 100) / 100,
    moonCakeDetails: goodsType.map(({ price, name }, i) => ({
      unitPrice: price,
      shopName: name,
      quantity: countsArr[i] || 0
    })).filter(({ quantity }) => quantity != 0),
    moonCakeAddress: isSelf[0] == 0 ? {
      consignee: buyForOther[0] ? consignee[0] : userName[0],
      address: address[0],
      tel: buyForOther[0] ? tel[0] : phone[0]
    } : null
  })
  .then((orderId) => doPay({ orderId, openid }).catch((err) => {
    history.push(`/mooncake/orders?orderId=${orderId}&phone=${phone[0]}&openid=${openid}`);
    throw err;
  }))
  .then((orderId) => {
    history.push(`/mooncake/success-page?orderId=${orderId}&phone=${phone[0]}&openid=${openid}`);
  }));

  return <div className="form-page">
    <div className="item-list">
      {goodsType.map((item, i) => ({ ...item, count: countsArr[i] || 0 }))
        .filter(({ count }) => count > 0)
        .map((item, i) => <Row className="item" key={i}>
        <img className="goods" src={item.imgs[0]} />
        <Col className="info">
          <h1>{item.name} <span className="price">￥{item.price}</span></h1>
          <div className="count">X {item.count}</div>
        </Col>
      </Row>)}
      <div className="amount-info">
        {isSelf[0] == 0 &&  <span className="fee">运费: ￥{fee}</span>}
        <span className="total-label">合计:</span>
        <span className="total-price">￥{total.toFixed(2)}</span>
      </div>
    </div>
    <div className="form-area">
      <h1>购买人信息</h1>
      <div className="form-item">
        <label>姓名</label>
        <FormInput model={userName} placeholder="请输入姓名" />
      </div>
      <div className="form-item">
        <label>手机号</label>
        {/* <FormInput model={phone} /> */}
        <PhoneField value={phone[0]} placeholder="请输入手机号码" onChange={(value: any) => phone[1](value)} />
      </div>
      <div className="form-item">
        <label>验证码</label>
        <FormInput model={authCode} placeholder="请输入验证码" maxLength={4} />
      </div>
      {third == 'false' && <div className="form-item">
        <label>取货方式</label>
        <RadioGroup value={isSelf[0].toString()} onChange={(val) => isSelf[1](parseInt(val, 10))}
          options={{ '0': '快递', '1': '到店自取' }}
        />
      </div>}
    </div>

    {isSelf[0] == 0 && <div className="form-area">
      <h1>收货人信息</h1>
      <div className="form-item label1">
        <label>是否替他人购买</label>
        <SwitchBtn model={buyForOther} />
      </div>
      {buyForOther[0] && <div className="form-item">
        <label>姓名</label>
        <FormInput model={consignee} placeholder="请输入收件人姓名"/>
      </div>}
      {buyForOther[0] && <div className="form-item">
        <label>手机号码</label>
        <FormInput model={tel} placeholder="请输入收件人手机号码" />
      </div>}
      <div className="form-item">
        <label>收货地址</label>
        <FormInput model={address} placeholder="请输入收件人地址" />
      </div>
      <div className="form-item">
        <label>发货时间</label>
        <FormSelect model={sendTime} options={dates.reduce((op, d) => ({ ...op, [d]: d }) , {})} />
      </div>
      <div className="form-item">
        <label>备注</label>
        <FormInput model={remark} placeholder="请填写附件信息(可选)" />
      </div>
    </div>}

    {isSelf[0] != 0 && <div className="form-area">
      <h1>到店自取</h1>
      <div className="form-item">
        <label>提货地点</label>
        <span className="txt">深圳市南山区海德二道 BEEPLUS超级烘焙工坊(中央酒岛吧台)</span>
      </div>
      <div className="form-item">
        <label>提货日期</label>
        <span className="txt">8月27日后</span>
      </div>
      <div className="form-item">
        <label>提货时间</label>
        <span className="txt">10：00-22：00</span>
      </div>
    </div>}
    <a href="javascript:;" className="submit-btn" onClick={submit}>立即支付</a>
  </div>;
}

const doPay = (param: PayRequestData): Promise<number> =>  {
  return post('/mooncake/pay', param).then((res: PayResponseData) => {
    return wechatPaySubmit(res.data);
  })
  .then(() => post('/mooncake/queryPayInfo', { orderId: param.orderId }, { transform: (r: any) => ({ status: 'success', ...r }) }))
  .then(({ code, message }) => {
    if (code == '0') {  // 成功
      return param.orderId;
    } else {
      throw new Error(message);
    }
  })
  // .catch((err) => {
  //   if (err.message != '支付取消') {
  //     throw err;
  //   }
  //   return param.orderId;
  // });
}

const useOrdersRequest = defineRequest<{ phone: string }, { result: OrderData[] }>('/mooncake/queryOrder');

const onInit = (func: () => void) => {
  const [isInit, setInit] = useState(false);
  useEffect(() => {
    if (!isInit) {
      func();
      setInit(true);
    }
  });
}

const OrderStatus: { [key:string]: string} = {
  '1': '待支付',
  '2': '待发货',
  '3': '已发货',
  '4': '待提取',
  '5': '已提取'
};

const OrdersPage = ({ location, history }: RouteComponentProps) => {
  const { phone, orderId = '', openid = '' } = parseQueryString(location.search);
  const [{ isLoading, data, error }, request] = useOrdersRequest();
  const [index, setIndex] = useState(0);

  onInit(() => request({ phone }));
  let content;

  if (isLoading || !data) {
    content = <div className="loading">正在加载中...</div>
  } else if (error) {
    content = <div className="error">{error.message}</div>
  } else if (data != null) {
    content = <div>
      <SwipeableViews onChangeIndex={(i) => setIndex(i)} index={index}>
        {data.result.map((item, i) => <div key={i}  className="order-item">
        <h1>订单详情 <span className={"order-status status-" + item.orderStatus}>{OrderStatus[item.orderStatus.toString()] || ''}</span></h1>
          <div className="item-list">
            {item.moonCakeDetails.map((goods, i) => {
                  const type = allGoodsType.find(({ name }) => goods.shopName == name);
                  // const goods = item.moonCakeDetails.find(({ shopName }) => shopName == type.name);
                  return { ... type!,
                    count: goods!.quantity || 0,
                    price: goods!.unitPrice || 0
                  };
                })
                .map((item, i) => <Row className="item" key={i}>
              <img className="goods" src={item.imgs[0]} />
              <Col className="info">
                <h1>{item.name} <span className="price">￥ {item.price}</span></h1>
                <div className="count">X {item.count}</div>
              </Col>
            </Row>)}
          </div>
          <div className="info-area">
            <div className="item">
              <label>编号</label>
              <span className="txt">{item.orderNo}</span>
            </div>
            <div className="item">
              <label>购买日期</label>
              <span className="txt">{item.createdTime}</span>
            </div>
            <div className="item">
              <label>取货方式</label>
              <span className="txt">{item.isSelf > 0 ? '到店自取' : '快递'}</span>
            </div>
            {item.moonCakeAddress != null && <div className="item">
              <label>收件人</label>
              <span className="txt">{item.moonCakeAddress.consignee}</span>
            </div>}
            {item.moonCakeAddress != null && <div className="item">
              <label>手机号码</label>
              <span className="txt">{item.moonCakeAddress.tel}</span>
            </div>}
            {item.moonCakeAddress != null && <div className="item">
              <label>收货地址</label>
              <span className="txt">{item.moonCakeAddress.address}</span>
            </div>}
            <div className="item">
              <label>物流状态</label>
              {item.expressNo == null || item.expressName == null ? <span className="txt"> -- </span> : <span className="txt">{item.expressName} {item.expressNo}</span>}
            </div>
          </div>
          <div className="amount-info">
            {item.isSelf == 0 && <span className="fee">运费: ￥{getAmount(item.moonCakeDetails.map(({ quantity }) => quantity)).fee}</span>}
            <span className="total-label">合计:</span>
            <span className="total-price">￥{item.totalMoney}</span>
            {item.orderStatus == 1 && <button className="submit" onClick={useLoading(() =>
              doPay({ orderId: item.orderId, openid }).then(() => history.push(`/mooncake/success-page?orderId=${orderId}&phone=${phone}&openid=${openid}`)))}
            >去支付</button>}
          </div>
        </div>)}
      </SwipeableViews>

      <div className="page-signs">
        {data.result.map((_, i) => <span key={i} className={i == index ? 'active' : ''} />)}
      </div>
    </div>
  }

  return <div className="orders-page">
    {content}
  </div>
}

const doSearch = async ({ phone = '', code = '' }) => {
  checkPhoneFormat(phone);
  assert(code != '', '验证码不可为空');
  return post('/member/checkCode', { phone, code });
};

const SearchForm = (props: RouteComponentProps) => {
  const phone = useState(''),
    authCode = useState('');

  const submit = useLoading(() =>
    doSearch({ code: authCode[0], phone: phone[0] })
    .then(() => post('/mooncake/queryOrder', { phone: phone[0] }).then(({ result }) => {
      if (!Array.isArray(result) || result.length == 0) {
        throw Error('没有查询到对应订单');
      }
    }))
    .then(() => doAuth(makeFullUrl(`/mooncake/orders?phone=${phone[0]}`))));

  return <div className="search-form">
    <img className="bg" src={require('../resources/img/bg.svg')} />
    <div className="form-area">
      <h1>订单查询</h1>
      {/* <div className="form-item">
        <label>姓名</label>
        <FormInput model={userName} placeholder="请输入姓名" />
      </div> */}
      <img className="decorate-person" src={require('../resources/img/search-form-deco.png')} />
      <div className="form-item">
        <label>手机号码</label>
        <PhoneField value={phone[0]} onChange={(val: any) => phone[1](val)} placeholder="请输入手机号码" />
      </div>
      <div className="form-item">
        <label>验证码</label>
        <FormInput model={authCode} maxLength={4} placeholder="请输入验证码" />
      </div>
    </div>
    <a href="javascript:;" className="submit-btn" onClick={submit}>立即查询</a>
    <div className="custorm-info">
      <div className="with-line">如您对订单有任何疑问，请联系我们的客服</div>
      <div>400-888-7000</div>
    </div>
    <FooterNav {... props} />
  </div>;
}

/**
 * 支付成功页面
 * @param param0
 */
const SuccessPage = ({ history, location }: RouteComponentProps) => {
  const { openid, phone, orderId } = parseQueryString(location.search);
  return <div className="mooncake-success-page">
    <div className="sign">
      <img src={require('../resources/img/success.svg')} />
      <span>支付成功!</span>
    </div>
    <a className="submit-btn" href="javascript:;" onClick={() => history.push(`/mooncake/orders?orderId=${orderId}&phone=${phone}&openid=${openid}`)}>回到订单页面</a>
  </div>;
}

const FooterNav = withRouter(({ location }: RouteComponentProps) => {
  const { employee = '', employeeName = '' } = parseQueryString(location.search);
  const searchStr = `employee=${employee}&employeeName=${employeeName}`;
  return <div className="footer-nav">
    <Link className={location.pathname.indexOf('/buy') > -1 ? 'active' : ''} to={`/mooncake/buy?${searchStr}`}>
      <i className="commodity" />
      <span>商品<br />Commodity</span>
    </Link>
    <Link className={location.pathname.indexOf('/search-form') > -1 ? 'active' : ''}  to={`/mooncake/search-form?${searchStr}`}>
      <i className="orderform" />
      <span>订单<br />Orderform</span>
    </Link>
  </div>;
});

const useRequestUserInfo = defineRequest<{ phone: string }, { result: null | { userName: string, phoneNumber: string }}>('/mooncake/getUserByPhoneNumber');

/**
 *  内部会员的链接生成页面
 */
const LinkCreator = ({ history }: RouteComponentProps) => {
  const [state, request] = useRequestUserInfo();
  const phone = useState('');
  const user = state.data && state.data.result ? state.data.result : null;
  const [isInit, setInit] = useState(false);

  return <div className="search-form link-creator">
    <div className="bg">
       <img src={require('../resources/img/bg.svg')} />
    </div>
    <div className="form-area">
      <img className="decorate-person" src={require('../resources/img/search-form-deco.png')} />
      <div className="form-item">
        <label>手机号码</label>
        <FormInput model={phone} placeholder="请输入你的手机号码" />
        <a href="javascript:;" className="btn" onClick={() => {
          try {
            checkPhoneFormat(phone[0])
            if (!state.isLoading) {
              setInit(true);
              request({ phone: phone[0] });
            }
          } catch(err) {
            alert(err.message);
          }
        }}>
          {state.isLoading ? '验证中..' : 'Beers验证'}
        </a>
      </div>
    </div>

    {!isInit && <div className="form-area result-area">
        <div style={{ textAlign: 'center' }}>Dear Beers，为了方便统计您推荐好友购买Bee+ <br />
中秋月饼的信息，请填写以上信息生成购买链接 <br />
预祝伙伴们月饼大卖！</div>
    </div>}

    {user != null && <div className="form-area result-area">
      <div className="successs-info">Dear <span className="name">{user.userName}</span>，恭喜您验证成功，请点击以下按钮 <br /> 生成推荐购买链接转发给好友</div>
    </div>}

    {user != null && <a href="javascript:;" className="submit-btn" onClick={() => history.push('/mooncake/buy?employee=' + user.phoneNumber + '&employeeName=' + encodeURIComponent(user.userName))}>
      生成推荐购买链接
    </a>}

    {user == null && isInit && <div className="form-area  result-area">
      <div className="info">非常抱歉，我们没有找到这个手机号码 <br /> 请联系商业技术发展中心小伙伴进行处理</div>
      <h1>联系方式</h1>
      <div className="form-item">
        <label>姓名</label>
        <span>王玉荣</span>
      </div>
      <div className="form-item">
        <label>手机号码</label>
        <span>15899773082</span>
      </div>
      <div className="form-item">
        <label>邮箱</label>
        <span>wangyurong@beeplus.cc</span>
      </div>
    </div>}
  </div>
}

/**
 * 第三方用的链接生成页面
 * @param param0
 */
const LinkCreator2 = () => {
  const [goodsId, setGoodsId] = useState('4');
  const goods = [[4, 140], [5, 150]];

  return <div className="buy-page">
    <img className="bg" src={require('../resources/img/mooncake-bg2.jpg')} />
    <div className="item-list">
      {goods.map(([id, price], i) => <Row className={"item " + (id.toString() == goodsId ? 'active' : '')} key={i} onClick={() => setGoodsId(id.toString())}>
        <img className="goods" src="http://cw-qiniu.beeplus.xyz/mooncake2.jpg" />
        <Col className="info">
          <h1>玉兔追月礼盒(六粒)</h1>
          <div className="desc">无论是与家人一起分享美味，还是想为心中的Ta送上一份别致的礼物，首选玉兔礼盒</div>
          <Row className="bot">
            <span className="price">￥{price}</span>
            {/* <NumBtn value={counts[i]} onChange={(v) => setCounts(v, i)} /> */}
          </Row>
        </Col>
      </Row>)}
    </div>
    <Link className="submit-btn" to={`/mooncake/buy?third=true&employee=13798985299&employeeName=${THIRD_PARTY_NAME}&goodsIds=${encodeURIComponent(JSON.stringify([parseInt(goodsId, 10)]))}`}>生成推荐购买链接</Link>
  </div>;


  // return <div>
  //   <div><a href="javascript:;" style={{ color: goodsId == '4' ? 'red' : '#000' }} onClick={() => setGoodsId('4')}>选择商品1: </a></div>
  //   <div><a href="javascript:;" style={{ color: goodsId == '5' ? 'red' : '#000' }} onClick={() => setGoodsId('5')}>选择商品2: </a></div>

  //   <Link to={`/mooncake/buy?employee=13798985299&employeeName=${THIRD_PARTY_NAME}&goodsIds=${encodeURIComponent(JSON.stringify([parseInt(goodsId, 10)]))}`}>生成链接</Link>
  // </div>
}

/**
 * 初始化微信设置
 */
const initWXsetting = async (win: any, { employeeName = '' }: any) => {
  if (win.wx) {
    const noncestr = 'WX_'+ Math.random();
    const timestamp = new Date().getTime().toString();
    const i = location.href.indexOf('#');
    const url = i > -1 ? location.href.slice(0, i) : location.href;
    const { data } = await post('/mooncake/getWXticket', { noncestr, timestamp, url });
    console.log('', data);
    wxconfig(win, { noncestr, timestamp, signature: data }, (wx) => {
      const op = {
        title: 'Beeplus 中秋月饼',
        desc: employeeName === THIRD_PARTY_NAME ? '' : `您的好友${employeeName}向您推荐了BEEPLUS中秋尊享月饼`,
        link: location.href,
        imgUrl: 'http://cw-qiniu.beeplus.xyz/mooncake1.jpg',
        success: function () {
            alert('已分享');
        },
        trigger: function (res: any) {
          // console.log(res);
          // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
          // alert('用户点击分享到朋友圈');
          // return false;
          //throw new Error('fdsfsd');
        },
        cancel: function () {
            // alert('已取消');
        },
        fail: function () {
            // alert(JSON.stringify(res));
        }
      };

      wx.onMenuShareAppMessage(op);
      wx.onMenuShareTimeline(op);
      wx.onMenuShareQQ(op);
      wx.onMenuShareWeibo(op);
      wx.onMenuShareQZone(op);
    });
  }
}

/**
 * 加载商品数据
 */
const getGoods = async (ids?: number[]): Promise<IGoodsType[]> => {
  // 如果没有接收到商品ID, 先尝试读缓存
  if (!ids) {
    const goods = window.localStorage.getItem('goodsType');
    if (goods) {
      allGoodsType = JSON.parse(goods);
      return goodsType = allGoodsType.filter((g) => g.isShow);
    }
  }
  const idArr = ids || [1,2,3];

  const { result } = await post('/mooncake/queryAllMoonCakeGood');
  console.log('goodsType',result);

  allGoodsType = result
    .map((p: any) => ({
      isShow: idArr.some(id => id == p.id),
      name: p.goodName || '',
      description: p.description || '',
      price: p.discountPrice || 0,
      imgs: JSON.parse(p.imageUrl || '[]')
    }));

  window.localStorage.setItem('goodsType', JSON.stringify(allGoodsType));

  return goodsType = allGoodsType.filter((g) => g.isShow);
}

const useRequestGoods = wrapUseState<any, IGoodsType[]>(async (params: any) => {
  await initWXsetting(window, params).catch((err) => console.error(err));
  return await getGoods(params.ids);
});

/**
 * 微信配置
 * @param win
 * @param param1
 * @param cb
 */
function wxconfig(win: any, { noncestr = '', timestamp = '', signature = '' }, cb: (wx: any) => void) {
  const wx = win.wx;
  wx.config({
    debug: false,
    appId: APP_ID,
    timestamp,
    nonceStr: noncestr,
    signature,
    jsApiList: [
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'onMenuShareQZone',
      'showMenuItems',
      'hideMenuItems'
    ]
  });



  wx.ready(() => {
    // alert('微信初始化完成');
    cb(wx);
  });

  wx.error(function(){
    alert('微信验证失败');
    // cb(wx);
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  });
}

/**
 * 根据情况隐藏或显示微信分享按钮
 * @param window
 * @param param1
 */
const hideOrShowMenuBtns = (window: any, { location }: RouteComponentProps) => {
  const wx = window.wx;
  if (wx) {
    const op = { menuList: [
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:share:weiboApp',
      'menuItem:share:facebook',
      'menuItem:share:QZone'
    ] };
    if (location.pathname.indexOf('/buy') > -1) {
      wx.showMenuItems(op);
    } else {
      wx.hideMenuItems(op);
    }
  }
}

const InnerRoute = (props: RouteComponentProps) => {
  const { match, location } = props;
  const { employeeName, goodsIds } = parseQueryString(location.search);
  const [{ isLoading, data, error }, request] = useRequestGoods();

  onInit(() => {
    console.log('employeeName', decodeURIComponent(employeeName));
    request({
      employeeName: decodeURIComponent(employeeName),
      ids: goodsIds ? JSON.parse(decodeURIComponent(goodsIds)) : (location.pathname.indexOf('/buy') > -1 ? [1,2,3] : undefined)
    });
  });

  useEffect(() => {
    document.title = 'Beeplus中秋月饼';

    hideOrShowMenuBtns(window, props);
    // request();
  });
  if (isLoading || !data) {
    return <div className="mooncake">
      加载中....
    </div>;
  } else if (error) {
    return <div className="mooncake">
      {error.message}
    </div>;
  } else return <div className="mooncake">
    {/* <img src={require("../resources/img/mooncake2.png")} style={{ width: }} /> */}
    <Route path={match.path + "/buy"} component={BuyPage}></Route>
    <Route path={match.path + "/form"} component={FormPage}></Route>
    <Route path={match.path + '/orders'} component={OrdersPage}></Route>
    <Route path={match.path + '/search-form'} component={SearchForm}></Route>
    <Route path={match.path + '/success-page'} component={SuccessPage}></Route>
    {/* <Route path={match.path + "/pay"} component={PayPage}></Route>
    <Route path={match.path + '/success'} component={ResultPage}></Route> */}
  </div>;
}

export default (props: RouteComponentProps) => {
  const { location } = props;
  console.log(location);
  if (location.pathname.indexOf('/third-link-create') > -1) {
    return <div className="mooncake"><LinkCreator2 /></div>;
  } else if (location.pathname.indexOf('/link-create') > -1) {
    return <div className="mooncake"><LinkCreator {... props} /></div>;
  } else {
    return <InnerRoute {... props} />;
  }
}


if (location.search.indexOf('?') > -1) {
  location.href = location.pathname + location.hash;
}

