import { createElement as _C } from 'react';
import { Row, Col, Icon, doAuth, parseQueryString } from './common';

const getResultUrl = (employee: string = '') => location.protocol + '//' + location.host + location.pathname + '#/register?employee=' + employee;

const infoTxt = [
  ['discount', '代金券', '7张总价值128元的无限制代金券'],
  ['card', '赠送免单券', '超级会员购买产品享20%~50%超值折扣'],
  ['vip', 'VIP专属通道', '超级会员专属排队通道，实名绑定'],
  ['cake', '生日特惠', '超级会员生日额外赠送多张优惠券权益'],
  ['svip', '赠送DAY PASS入场券', '赠送三张Beeplus旗下广州、深圳、珠海三地通用Day Pass入场券及额外购买享受超低折扣'],
  ['burn', '烘焙课程', '优先或以折扣价格参与Beeplus旗下所有烘焙课程、社群活动']
];

const submit = (history: any) => {
  const { employee } = parseQueryString(history.location.search);
  doAuth(getResultUrl(employee));
};

export default ({ history }: any) => <div className="intro">
  <Col className="page-frame">
    <h1>成为BEEPLUS超级会员</h1>
    <div className="subtitle">尽|享|6|大|优|惠</div>
    {infoTxt.map(([icon, title, desc]) => <Row className="intro-item" key={title} center>
      <Icon type={icon} />
      <Col className="txt-area">
        <span className="title">{title}</span>
        <span className="desc">{desc}</span>
      </Col>
    </Row>)}
    <a href="javascript:;" onClick={() => submit(history)} className="subscrib-btn">
      <span className="txt">立刻成为超级会员 开启优惠之旅 |</span>
      <span className="price">128/年</span>
    </a>
  </Col>
</div>;

