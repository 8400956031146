import { createElement as _C, useState } from 'react';
import { createPortal } from 'react-dom';
import { Col, Row, Icon, useLoading, post, parseQueryString, sleep, alert, countdown, wechatPaySubmit } from './common';
import { now } from './datetime';
import { observer } from 'mobx-react';

const theAmount = 128;

const payChannels = [
  ['wechat', '微信支付'],
  ['alipay', '支付宝']
];

const submit = useLoading(async ({ selectedChannel, history }: any) => {
  const useAlipay = selectedChannel == 'alipay';
  const { id, name, openid, type } = parseQueryString(history.location.search);

  if (!id) {
    setTimeout(() => history.push('/register'), 1000);
    throw new Error('请先完成会员注册');
  }
  if (useAlipay) {
    const tradeNo = 'AL' + new Date().getTime().toString();
    sessionStorage.setItem('alipay-prompt-time', countdown.now + 5000);
    sessionStorage.setItem('alipay-trade-no', tradeNo);
    history.push(`/pay?alipay=true&id=${id}&name=${name}&openid=${openid}&type=${type}&tradeNo=${tradeNo}`);
  } else {
    const { data, trade_no } = await post('/memberPay/wechatOrder', { openid, type, memberId: id, money: theAmount });
    console.log(data, trade_no);
    // await sleep(6);
    await wechatPaySubmit(data);
    await checkPay(trade_no, 2);
    alert('支付完成');
    history.push('/success');
  }
});



/**
 * 轮询请求接口直至确认支付成功
 * @param trade_no 订单号
 * @param type 1 - 支付宝  2 - 微信
 */
const checkPay = async (trade_no: string, type: number, tryTime = 1) => {
  const { code, message } = await post('/member/queryPayInfo', { tradeNo: trade_no, payType: type }, { transform: (r: {}) => ({ status: 'success', ...r }) });
  if (code == '0') {  // 成功
    return;
  } else if (code == '-1') {
    throw new Error(message);
  } else {  // 需要重试
    if (tryTime > 5) {   // 重试超过 5 次就报错
      throw new Error('支付较单失败');
    }
    await sleep(tryTime);
    await checkPay(trade_no, type, tryTime + 1);
  }
}

// const wechatPaySubmit = async (payOptions: any) => {
//   return new Promise((resolve, reject) => {
//     const { WeixinJSBridge } = window as any;
//     console.log(payOptions);
//     WeixinJSBridge.invoke('getBrandWCPayRequest', payOptions, (res: any) => {
//       if (res.err_msg == "get_brand_wcpay_request:ok") {
//         // 使用以上方式判断前端返回,微信团队郑重提示：
//         //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
//         resolve();
//       } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
//         reject(new Error('支付取消'));
//       } else {
//         reject(new Error('支付失败'));
//       }
//     });
//   });
// };

/**
 * 检查支付宝支付状态
 */
const checkAlipay = useLoading(async ({ trade_no }: any) => {
  await checkPay(trade_no, 1);
});

/**
 * 打开支付宝跳转提示
 */
const Prompt = observer(({ history, type }: any) => {
  const ua = navigator.userAgent.toLowerCase();


  // 如果不在页面不是在微信内部, 跳转至支付宝页面, 呼出手机内支付宝
  if (ua.indexOf('micromessenger') === -1) {
    const { name, id, tradeNo } = parseQueryString(history.location.search);
    location.href = `/server-next/memberPay/aliPayForward?userName=${name}&memberId=${id}&money=${theAmount}&type=${type}&tradeNo=${tradeNo}`
  }

  const trade_no = sessionStorage.getItem('alipay-trade-no');
  const showTime = parseInt(sessionStorage.getItem('alipay-prompt-time') || '0', 10);


  return createPortal(<Col className="alipay-prompt">
    <div className="arrow"></div>
    <div className="info">步骤一：点击右上角按钮</div>
    <div className="info">步骤二：选择“在浏览器打开” </div>
    {countdown.now > showTime && <button onClick={() => checkAlipay({ trade_no }).then(() => history.push('/success'))}>已完成支付</button>}
  </Col>, document.body);
});

export default ({ history }: any) => {
  const [selectedChannel, setSelectedChannel] = useState('wechat');
  const { alipay, type } = parseQueryString(history.location.search);
  return <div className="pay-channel">
    <Col className="page-frame">
      <Col className="item amount-area" center>
        <h1>支付金额</h1>
        <Row className="amount-info-area" center>
          <span className="amount">¥128</span>
          <span className="unit">/年</span>
        </Row>
        <span className="extra-info">收款方: 深圳蜜蜂科技有限公司</span>
      </Col>
      {payChannels.map(([channel, txt, disabled]) => <Row key={channel} className="item channel" onClick={() => !disabled && setSelectedChannel(channel)}>
        <Icon type={channel} size={20}></Icon>
        <span className="title">{txt} {disabled ? '(暂不开放)' : ''}</span>
        {/* <a href="javascript:;" onClick={() => setSelectedChannel(channel)}> */}
        <Icon type={selectedChannel == channel ? "checked" : 'unchecked'} size={20} />
        {/* </a> */}
      </Row>)}
      <button className="submit-btn" onClick={() => {
        submit({ selectedChannel, history });
      }}>去支付</button>
    </Col>
    {alipay && <Prompt history={history} type={type} />}
  </div>;
}
