import { createElement as _C, useState } from 'react';
import { Col, Row, Icon } from './common';

export default () => <div className="success-page">
  <Col className="page-frame">
    <Col center>
      <h1>恭喜您成为BEEPLUS超级会员</h1>
      <div className="qrcode">
        <img src={require('../resources/img/qrcode.jpeg')} />
      </div>
      <div className="info2">
        长按识别小程序码<br /> 进入会员中心
      </div>
    </Col>
  </Col>
</div>
