import { createElement as _C, useState } from 'react';
import { Col, useLoading, post, parseQueryString, doAuth } from './common';
import { FormItem, PhoneField, checkPhoneFormat } from './form';

const submit = useLoading(async ({ history, phone, code }: any) => {
  checkPhoneFormat(phone);
  if (!code) {
    throw new Error('请填写验证码');
  }

  await post('/member/checkCode', { phone, code });
  const res = await post('/member/queryMemberInfo', { phone });
  console.log(res);
  const { userName, id, openId } = res.result;

  history.push(`/pay?id=${id}&name=${encodeURIComponent(userName)}&openid=${openId}&type=2`);
  return '';
});

const checkPhone = async (phone: string) => {
  const res = await post('/member/checkPhone', { phone });
  if (!res.result) {
    throw new Error(phone + '尚未注册, 需要先注册');
  }
  return true;
}

export default ({ history }: any) => {
  const [code, setVerifyCode] = useState(''),
    [phone, setPhone] = useState('');
  const { openid } = parseQueryString(history.location.search);
  // if (!openid) {
  //   doAuth(location.href);
  // }

  return <div className="login-form">
    <div className="page-frame">
      <Col className="inner-form">
        <h1>BEEPLUS超级会员登录</h1>
        <FormItem label="手机号码">
          <PhoneField value={phone} onChange={setPhone} checkPhone={checkPhone} />
        </FormItem>
        <FormItem label="验证码">
          <input type="text" maxLength={4} value={code} onChange={(evt) => setVerifyCode(evt.target.value)} />
        </FormItem>

        <button className="submit-btn" onClick={() => submit({ phone, code, history })}>立即绑定</button>
      </Col>
    </div>
  </div>
};
