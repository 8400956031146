import { createElement as _C, useState } from 'react';
import { Col, Row, useLoading, countdown, post, alert, parseQueryString } from './common';
import { now, fromString } from './datetime';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Select } from './select';
import { DatePicker } from './datepicker';

export const FormItem = (props: any) => <Col className="form-item">
  <label>{props.label}</label>
  <Row>{props.children}</Row>
</Col>

const df = 'YYYY-MM-DD';

function range(start: number, end: number) {
  return new Array(end - start).fill(0).map((_, i) => start + i);
}

/**
 * 发送验证码倒计时状态管理对象
 */
const codeCountDown = observable({

  endTime: parseInt(sessionStorage.getItem('phone-end-time') || '0', 10),

  get remain() {
    return Math.max(0, Math.round((this.endTime - countdown.now) / 1000));
  }
});

export const checkPhoneFormat = (phone: string, message = '请输入正确的手机号码') => {
  if (!/^\d{11}$/.test(phone)) {
    throw new Error(message);
  }
}

/**
 * 检查手机号是否已被注册
 * @param phone
 */
const checkPhoneExists = async (phone: string) => {
  const res = await post('/member/checkPhone', { phone });
  if (res.result) {
    throw new Error('该手机号已被注册!');
  }
  return true;
}

/**
 * 提交表单
 */
const submit = useLoading(async ({ employee, history, userName, sex, birthday, phone, code, openId }: any) => {
  checkPhoneFormat(phone);
  if (!userName) {
    throw new Error('请填写姓名');
  }
  if (!code) {
    throw new Error('请输入验证码');
  }
  if (!birthday) {
    throw new Error('请输入生日');
  }

  await post('/member/checkCode', { phone, code });

  const { result } = await post('/member/register', { source: 4, sourceChannel: 1, employee, userName, sex, birthday, phone, code, openId });
  history.push(`/pay?id=${result}&name=${encodeURIComponent(userName)}&openid=${openId}&type=1`);
});

/**
 * 发送验证码按钮组件
 * @param param0
 */
export const PhoneField = observer(({ value = '', onChange = (p: string) => '', checkPhone = (p: string) => Promise.resolve(), ... others }) => {
  const sendCode = useLoading(async () => {
    checkPhoneFormat(value);
    await checkPhone(value);

    runInAction(() => {
      codeCountDown.endTime = new Date().getTime() + 60 * 1000;
      sessionStorage.setItem('phone-end-time', codeCountDown.endTime.toString());
    });

    await post('/member/sendValidCode', { phone: value });
    alert('验证码已发送');
  });

  return <div className="phone">
    <input type="text" value={value} readOnly={codeCountDown.remain > 0} onChange={(evt) => onChange(evt.target.value)} {... others}></input>
    <button className="send-code-btn" disabled={codeCountDown.remain > 0} onClick={sendCode}>
      {codeCountDown.remain > 0 ? `请稍等${codeCountDown.remain}秒` : '获取验证码'}
    </button>
  </div>;
});

/**
 * 一个简单的日历控件
 * @param param0
 */
// const _DateSelector = ({ value = '1990-01-01', onChange = (str: string) => { } }) => {
//   const today = now();
//   const date = fromString(value, df) || now();

//   return <Row className="birthday-group">
//     <Select value={date.year}
//       options={range(today.year - 100, today.year + 1).reduce((obj, year) => ({ ... obj, [year]: year + '年'}), {})}
//       onChange={(evt) => onChange(date.change({ year: evt.target.value }).format(df))} />
//     <Select value={date.month}
//       options={range(1, 13).reduce((obj, month) => ({ ... obj, [month]: month + '月'}), {})}
//       onChange={(evt) => onChange(date.change({ month: evt.target.value }).format(df))} />
//     <Select value={date.date}
//       options={range(1, date.endOf('month').date + 1).reduce((obj, day) => ({ ... obj, [day]: day + '日'}), {})}
//       onChange={(evt) => onChange(date.change({ date: evt.target.value }).format(df))} />
//   </Row>;
// }

export default ({ history }: any) => {
  const { openid, employee, phoneNum } = parseQueryString(history.location.search);

  // console.log(history);
  const [userName, setName] = useState(''),
    [birthday, setBirthday] = useState(''),
    [code, setVerifyCode] = useState(''),
    [sex, setSex] = useState('1'),
    [phone, setPhone] = useState(phoneNum || '');

  return <div className="register-form page-frame">
    <Col className="inner-form">
      <h1>注册成为BEEPLUS超级会员</h1>
      <FormItem label="姓名">
        <div className="text-input">
          <input type="text" value={userName} onChange={(evt) => setName(evt.target.value)} />
        </div>
      </FormItem>
      <FormItem label="性别">
        <Select value={sex} onChange={(evt) => setSex(evt.target.value)} options={{ '1': '男', '2': '女' }}>
        </Select>
      </FormItem>
      <FormItem label="生日">
        <DatePicker value={birthday} onChange={setBirthday} />
      </FormItem>
      <FormItem label="手机号码">
        <PhoneField value={phone} onChange={setPhone} checkPhone={checkPhoneExists} />
      </FormItem>
      <FormItem label="验证码">
        <div className="text-input">
          <input type="text" maxLength={4} value={code} onChange={(evt) => setVerifyCode(evt.target.value)} />
        </div>
      </FormItem>

      <button className="submit-btn" onClick={() => submit({ employee, history, userName, birthday, phone, code, sex, openId: openid })}>立即绑定</button>
    </Col>
  </div>;
}
